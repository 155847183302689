import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, switchMap } from 'rxjs';
import { ApplicationSettings } from '../../general/interfaces/application-settings.interface';
import { AppsettingsService } from '../../general/shared/services/appsettings/appsettings.service';
import { setDateFilter } from '../date-filter/date-filter.actions';
import { setWorkflowShift } from '../workflow-shift/workflow-shift.action';
import { saveDateFilterAndWorkflowShift } from './date-filter-and-workflow-shift.action';

@Injectable()
export class DateFilterAndWorkflowShiftEffects {
	constructor(
		private actions$: Actions,
		private appSettingsService: AppsettingsService
	) {}

	saveDateFilterAndWorkflowShift$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveDateFilterAndWorkflowShift),
				switchMap((action) => {
					return this.appSettingsService
						.saveDateFilterAndWorkflowShift(action.dateFilter, action.workflowShift)
						.pipe(
							mergeMap((filter: ApplicationSettings) => [
								setDateFilter({ value: filter.dateSelector }),
								setWorkflowShift({ value: filter.dateSelector.workflowShift }),
							])
						);
				})
			),
		{ dispatch: true }
	);
}
