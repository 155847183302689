import { Vehicle } from '@agilox/common';
import { NotifyComponent } from '@agilox/ui';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	inject,
	Input,
	input,
	InputSignal,
} from '@angular/core';
import { AppsettingsService } from '../../../services/appsettings/appsettings.service';
import { SubscriptionNotifyTranslationPipe } from '../data/pipes/subscription-notify-translation.pipe';

@Component({
	selector: 'agilox-analytics-subscription-notify',
	templateUrl: './subscription-notify.component.html',
	standalone: true,
	imports: [CommonModule, NotifyComponent, SubscriptionNotifyTranslationPipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionNotifyComponent {
	public readonly appsettingsService = inject(AppsettingsService);
	@Input({ required: true }) vehicles: Vehicle[] | undefined;
	@Input({ required: true }) type: 'expired' | 'expire_soon' = 'expired';
}
