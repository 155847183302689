import { Vehicle } from '../interfaces/vehicle.interface';

/**
 * Calculate the remaining days of a subscription
 */
export function getSubscriptionRemaining(subscriptionEndDate: number | undefined) {
	if (!subscriptionEndDate) {
		return 0;
	}

	if (subscriptionEndDate > 0) {
		const today = new Date();
		const subscriptionEnd = new Date(subscriptionEndDate * 1000);
		const timeDifference = subscriptionEnd.getTime() - today.getTime();
		const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
		return Math.max(remainingDays, 0);
	} else {
		return 0;
	}
}

export function isSubscriptionExpired(vehicle: Vehicle | undefined): boolean {
	if (!vehicle) {
		return false;
	}
	return vehicle.subscription_end * 1000 < Date.now() && vehicle.subscription_end > 0;
}

export function isSubscriptionExpiringSoon(vehicle: Vehicle | undefined): boolean {
	if (!vehicle) {
		return false;
	}
	const timeLeft = vehicle.subscription_end * 1000 - Date.now();
	const expiresSoonTimestamp = 30 * 24 * 60 * 60 * 1000; // 30 days
	const isAboutToExpire = timeLeft > 0 && timeLeft < expiresSoonTimestamp;
	return isAboutToExpire;
}
