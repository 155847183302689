<div class="datepicker-navigation max-[370px]:scale-90">
	<form [formGroup]="formGroup" class="datepicker-navigation__form">
		<ui-icon
			icon="x_arrow_left"
			(iconClicked)="goToPreviousMonth()"
			class="datepicker-navigation__icon"
			[disabled]="previousMonthDisabled || disabled"
			[class.is-disabled]="previousMonthDisabled"
			size="m"
		></ui-icon>
		<ui-icon
			icon="x_arrow_right"
			(iconClicked)="goToNextMonth()"
			class="datepicker-navigation__icon"
			[disabled]="nextMonthDisabled || disabled"
			[class.is-disabled]="nextMonthDisabled || disabled"
			size="m"
		></ui-icon>

		<ui-icon
			icon="cal_check"
			(iconClicked)="todayClick.emit()"
			class="datepicker-navigation__icon"
			[disabled]="disabled"
			[class.is-disabled]="disabled"
			size="m"
		></ui-icon>

		<div class="datepicker-navigation__selects">
			<ui-select
				[options]="
					monthSelectOptions | monthSelect: formGroup.get('year')?.value : minDate : maxDate
				"
				formControlName="month"
				class="datepicker-navigation__monthSelect"
			></ui-select>
			<ui-select
				[options]="yearSelectOptions | yearSelect: minDate : maxDate"
				formControlName="year"
				class="w-[87px]"
			></ui-select>
		</div>
	</form>
</div>
