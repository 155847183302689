<!-- Main Content -->
<div
	class="hidden fixed bottom-0 left-0 right-0 top-0 transition-all ease-out z-offcanvas shadow-around bg-primary-100 md:absolute md:left-[calc(100%-31.125rem)] md:w-[31.125rem] md:pt-8 md:pb-8 pb-4 pt-4 {{
		styleClass
	}}"
	[ngClass]="{ '!flex flex-col': open }"
	[@slideInOut]="open ? 'open' : 'closed'"
	data-cy="ui-offcanvas"
>
	<div class="flex justify-between py-0 px-4 md:px-8">
		<ui-headline type="h3">{{ header | translate }}</ui-headline>
		<ui-icon
			*ngIf="showCloseIcon"
			icon="x_cross"
			size="m"
			[action]="true"
			(iconClicked)="close()"
			data-cy="ui-offcanvas-close-icon"
		></ui-icon>
	</div>

	<div class="flex-grow max-h-full overflow-y-auto px-4 py-0 w-full md:flex-grow-0 md:px-8">
		<ng-content></ng-content>
	</div>
	<div class="pl-4 pr-4 pt-6 w-full md:pl-8 md:pr-8">
		<ng-content select="[offcanvas-buttons]"></ng-content>
	</div>
</div>
<!-- Backdrop - Displayed only when open and closeOnOutsideClick is true -->
<div
	class="absolute top-0 bottom-0 left-0 right-0 z-offcanvas-backdrop"
	[ngClass]="{ hidden: !open || !closeOnOutsideClick }"
	(click)="close()"
	data-cy="ui-offcanvas-backdrop"
></div>
