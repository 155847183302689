import { createAction, props } from '@ngrx/store';
import { WorkflowShift } from './workflow-shift.state';

export const saveWorkflowShift = createAction(
	'[Workflow Shift] Save',
	props<{ value: WorkflowShift }>()
);

export const setWorkflowShift = createAction(
	'[Workflow Shift] Set',
	props<{ value: WorkflowShift }>()
);
