@if (!appsettingsService.isInternalUser() && vehicles) {
	<ui-notify
		data-cy="subscription-notify"
		[text]="
			vehicles
				| subscriptionNotifyTranslation
					: 'general.subscription_for_vehicle_' + type
					: 'general.subscription_for_vehicles_' + type
		"
		[type]="type === 'expired' ? 'warning' : 'info'"
	></ui-notify>
}
