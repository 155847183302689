import { Vehicle } from '@agilox/common';
import { DatePipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getEarliestExpireDate',
	standalone: true,
})
export class GetEarliestExpireDatePipe implements PipeTransform {
	constructor(private datePipe: DatePipe) {}

	transform(vehicles: Vehicle[]): string | null {
		if (!vehicles || vehicles.length === 0) {
			return undefined;
		}

		const dates = vehicles.map((vehicle: Vehicle) => {
			return vehicle.subscription_end * 1000;
		});

		const earliestDate = Math.min(...dates);

		return this.datePipe.transform(earliestDate, 'shortDate');
	}
}
