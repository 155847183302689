import { OnboardingAction, OnboardingInterface } from '@agilox/common';

export const analyticsOnboarding: Array<OnboardingInterface> = [
	{
		headline: 'onboarding.slide1.headline',
		text: 'onboarding.slide1.text',
		buttonText: 'general.next',
		buttonAction: OnboardingAction.NEXT,
		linkText: 'general.skip',
		linkAction: OnboardingAction.SKIP,
	},
	{
		image: {
			en: 'assets/images/onboarding/en/slide2.webp',
			de: 'assets/images/onboarding/de/slide2.webp',
			maxHeight: '585px',
		},
		headline: 'onboarding.slide2.headline',
		text: 'onboarding.slide2.text',
		buttonText: 'general.next',
		buttonAction: OnboardingAction.NEXT,
		linkText: 'general.back',
		linkAction: OnboardingAction.BACK,
	},
	{
		image: {
			en: 'assets/images/onboarding/en/slide3.webp',
			de: 'assets/images/onboarding/de/slide3.webp',
			maxHeight: '300px',
		},
		headline: 'onboarding.slide3.headline',
		text: 'onboarding.slide3.text',
		buttonText: 'general.next',
		buttonAction: OnboardingAction.NEXT,
		linkText: 'general.back',
		linkAction: OnboardingAction.BACK,
	},
	{
		image: {
			en: 'assets/images/onboarding/en/slide4.webp',
			de: 'assets/images/onboarding/de/slide4.webp',
			maxHeight: '585px',
		},
		headline: 'onboarding.slide4.headline',
		text: 'onboarding.slide4.text',
		buttonText: 'general.next',
		buttonAction: OnboardingAction.NEXT,
		linkText: 'general.back',
		linkAction: OnboardingAction.BACK,
	},
	{
		image: {
			en: 'assets/images/onboarding/en/slide5.webp',
			de: 'assets/images/onboarding/de/slide5.webp',
			maxHeight: '300px',
		},
		headline: 'onboarding.slide5.headline',
		text: 'onboarding.slide5.text',
		buttonText: 'general.done',
		buttonAction: OnboardingAction.DONE,
		linkText: 'general.back',
		linkAction: OnboardingAction.BACK,
	},
];
