import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationSettings } from '../../general/interfaces/application-settings.interface';
import { AppsettingsService } from '../../general/shared/services/appsettings/appsettings.service';
import { saveDateFilter, setDateFilter } from './date-filter.actions';

@Injectable()
export class DateFilterEffects {
	constructor(
		private actions$: Actions,
		private appSettingsService: AppsettingsService
	) {}

	saveDateFilter$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveDateFilter),
				mergeMap((action) => {
					return this.appSettingsService
						.saveDateFilter(action.value)
						.pipe(
							map((filter: ApplicationSettings) => setDateFilter({ value: filter.dateSelector }))
						);
				})
			),
		{ dispatch: true }
	);
}
