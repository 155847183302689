import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs';
import { ApplicationSettings } from '../../general/interfaces/application-settings.interface';
import { AppsettingsService } from '../../general/shared/services/appsettings/appsettings.service';
import { setDateFilter } from '../date-filter/date-filter.actions';
import { saveWorkflowShift, setWorkflowShift } from './workflow-shift.action';

@Injectable()
export class WorkflowShiftEffect {
	constructor(
		private actions$: Actions,
		private appSettingsService: AppsettingsService
	) {}

	saveWorkflowShift$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveWorkflowShift),
				mergeMap((action) =>
					this.appSettingsService
						.saveWorkflowShift(action.value)
						.pipe(
							mergeMap((settings: ApplicationSettings) => [
								setWorkflowShift({ value: settings.dateSelector.workflowShift }),
								setDateFilter({ value: settings.dateSelector }),
							])
						)
				)
			),
		{ dispatch: true }
	);
}
