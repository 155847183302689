import { createFeature, createReducer, on } from '@ngrx/store';
import { setWorkflowShift } from './workflow-shift.action';

export interface WorkflowShift {
	shiftOn: boolean;
	start: number;
	length: number;
	numberOfShifts: number;
}

export const workflowShiftInitialState: WorkflowShift = {
	shiftOn: false,
	start: 21600,
	length: 28800,
	numberOfShifts: 1,
};

const reducer = createReducer(
	workflowShiftInitialState,
	on(setWorkflowShift, (state, action) => ({
		...state,
		...action.value,
	}))
);

export const workflowShiftFeature = createFeature({ name: 'workflowShift', reducer });
