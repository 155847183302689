import { ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Unsubscribe } from './unsubscribe';

/**
 * Represents a class that handles unsaved changes in a form.
 * It extends the Unsubscribe class to manage subscriptions.
 * This class tracks changes in a FormGroup and prompts the user with a modal dialog
 * if there are unsaved changes when attempting to deactivate the form.
 * The class provides methods to open and close the modal dialog and emits an event
 * when changes are saved or discarded.
 */
export class FormUnsavedChangesHandler extends Unsubscribe {
	changesSaved: Subject<boolean> = new Subject<boolean>();

	overlayOpen: boolean = false;

	form: FormGroup = new FormGroup({});

	constructor(private cdRef: ChangeDetectorRef) {
		super();
	}

	canDeactivate(): Observable<boolean> | boolean {
		if (this.form.dirty) {
			this.overlayOpen = true;
			this.cdRef.markForCheck();
			return this.changesSaved.asObservable();
		}
		return true;
	}

	closeModal() {
		this.changesSaved.next(false);
		this.overlayOpen = false;
	}
}
