import { createAction, props } from '@ngrx/store';
import { DateFilter } from './date-filter.state';

export const setDateFilter = createAction(
	'[Global Date Filter] Set',
	props<{ value: DateFilter }>()
);

export const saveDateFilter = createAction(
	'[Global Date Filter] Save',
	props<{ value: DateFilter }>()
);
