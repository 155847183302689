import { DatePipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'subscriptionTranslationParams',
	standalone: true,
})
export class SubscriptionTranslationParamsPipe implements PipeTransform {
	private datePipe: DatePipe = inject(DatePipe);
	transform(option: any): { machines: string; date: string } {
		const machines = option?.value?.name || '-';
		const subscriptionEnd = option?.value?.subscription_end;
		const date = subscriptionEnd
			? this.datePipe.transform(subscriptionEnd * 1000, 'shortDate')
			: '-';

		return { machines: machines, date: date ?? '-' };
	}
}
