import { environment } from '@analytics/env/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionStation } from './action-station';
import { Failure } from './failure';
import { Order } from './order';

/**
 * is responsible for fetching orders, failures and workflows
 */
@Injectable({
	providedIn: 'root',
})
export class FailureOrderWorkflowService {
	constructor(
		private http: HttpClient,
		private appsettings: AppsettingsService
	) {}

	/**
	 * fetches all orders from the server
	 * @param observer gets notified
	 */
	getOrders(observer: (data: Array<Order> | string) => void, serials: Array<string>): Subscription {
		const reqObj = this.appsettings.generateReqObject(true);

		/**
		 * Shift is not needed for the orders
		 */
		if (reqObj.shift) {
			reqObj.shift.on = false;
		}

		if (reqObj.serial.length !== 0 && serials.length) {
			if (serials) {
				reqObj.serial = serials;
			}
			return this.http
				.post(environment.server + '/v2/Stats/order', reqObj)
				.pipe(
					map((data: any) => {
						if (data.data) {
							const arr: Array<Order> = new Array<Order>();
							for (const item of data.data) {
								arr.push(Order.generateFromJson(item));
							}
							return arr;
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * fetches all orders from the server
	 * @param observer gets notified
	 * @param serials all serials
	 */
	getActionStations(
		observer: (data: Array<ActionStation> | string) => void,
		serials?: Array<string>
	): Subscription {
		const reqObj = this.appsettings.generateReqObject();
		if (reqObj.serial.length !== 0) {
			if (serials?.length) {
				reqObj.serial = serials;
			}
			return this.http
				.post(environment.server + '/v2/Stats/actions', reqObj)
				.pipe(
					map((data: any) => {
						if (data.data) {
							const result = new Array<ActionStation>();
							for (const item of data.data as Array<any>) {
								result.push(ActionStation.parseDataFromResponse(item));
							}
							return result;
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * fetches the same data as getFailures, but for the widgets-fetchfunction we need other parameters
	 * @param observer observer
	 * @param renderedInShowAll if all machines should get rendered
	 * @returns Subscription
	 */
	getOrdersForWidgets(
		observer: (data: Array<Order>) => void,
		renderedInShowAll = false
	): Subscription {
		const reqObj = this.appsettings.generateReqObject(renderedInShowAll);
		if (reqObj.serial.length !== 0) {
			return this.http
				.post(environment.server + '/v2/Stats/order', reqObj)
				.pipe(
					map((data: any) => {
						if (data.data) {
							const arr = new Array<Order>();
							for (const item of data.data) {
								arr.push(Order.generateFromJson(item));
							}
							return arr;
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * fetches the failures from the server and returns the subscription for manual unsubscribing
	 * @param observer gets notified
	 */
	getFailures(
		observer: (data: Array<Failure> | string) => void,
		serials?: Array<string>
	): Subscription {
		const reqObj = this.appsettings.generateReqObject(true);
		if (reqObj.serials?.length !== 0) {
			if (serials?.length) {
				reqObj.serial = serials;
			}

			return this.http
				.post(environment.server + '/v2/Stats/failureLog', reqObj)
				.pipe(
					map((data: any) => {
						if (data.data) {
							const arr = new Array<Failure>();
							for (const item of data.data) {
								arr.push(Failure.generateFromJson(item));
							}
							return arr;
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * fetches the same data as getFailures, but for the widgets-fetchfunction we need other parameters
	 * @param observer observer
	 * @param renderedInShowAll if all machines should get rendered
	 * @returns Subscription
	 */
	getFailuresForWidgets(
		observer: (data: Array<Failure>) => void,
		renderedInShowAll = false
	): Subscription {
		const reqObj = this.appsettings.generateReqObject(renderedInShowAll);
		if (reqObj.serial.length !== 0) {
			return this.http
				.post(environment.server + '/v2/Stats/failureLog', reqObj)
				.pipe(
					map((data: any) => {
						if (data.data) {
							const arr = new Array<Failure>();
							for (const item of data.data) {
								arr.push(Failure.generateFromJson(item));
							}
							return arr;
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}
}
