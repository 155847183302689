import { isSubscriptionExpired, Vehicle } from '@agilox/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getExpiredVehicles',
	standalone: true,
})
export class GetExpiredVehiclesPipe implements PipeTransform {
	transform(vehicles: Vehicle[] | Vehicle | undefined): Vehicle[] | undefined {
		if (!vehicles) {
			return undefined;
		}
		const array = Array.isArray(vehicles) ? vehicles : [vehicles];
		const expired = array.filter(isSubscriptionExpired);
		return expired.length > 0 ? expired : undefined;
	}
}
