import { PluralTranslatePipe, Vehicle } from '@agilox/common';
import { DatePipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetEarliestExpireDatePipe } from '../../../../pipes/get-earliest-expire-date.pipe';
import { SubscriptionVehicleDisplayPipe } from './subscription-vehicle-display.pipe';

@Pipe({
	name: 'subscriptionNotifyTranslation',
	standalone: true,
})
export class SubscriptionNotifyTranslationPipe implements PipeTransform {
	private translate = inject(TranslateService);
	private datePipe = inject(DatePipe);

	transform(vehicles: Vehicle[], singularTranslation: string, pluralTranslation: string): string {
		if (!vehicles || vehicles.length === 0) {
			return '';
		}

		const machines = new SubscriptionVehicleDisplayPipe().transform(vehicles);
		const earliestSubscriptionEnd = new GetEarliestExpireDatePipe(this.datePipe).transform(
			vehicles
		);

		return new PluralTranslatePipe(this.translate).transform(
			vehicles.length,
			singularTranslation,
			pluralTranslation,
			{
				machines: machines,
				date: earliestSubscriptionEnd ?? '-',
			}
		);
	}
}
