import { SignJWT } from 'jose';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable, switchMap } from 'rxjs';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
	private secret = new TextEncoder().encode('secret'); // Secret key

	constructor() {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.getToken()).pipe(
			switchMap((token) => {
				const modifiedReq = req.clone({
					setHeaders: token ? { Authorization: `Bearer ${token}` } : {},
				});
				return next.handle(modifiedReq);
			})
		);
	}

	// Placeholder function that gets the accessToken from where it is stored. e.g. authService.getToken() from the auth lib or the localStorage
	private async getToken(): Promise<string> {
		const payload = { sub: 'marc.koppler@agilox.net' }; // Payload

		// Generate the JWT token
		const token = await new SignJWT(payload).setProtectedHeader({ alg: 'HS256' }).sign(this.secret);

		return token;
	}
}
