import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { HeadlineComponent } from '../headline/headline.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../icon/icon.module';
import { NgClass, NgIf } from '@angular/common';
import { offcanvasAnimations } from './offcanvas.animations';

@Component({
	selector: 'ui-offcanvas',
	standalone: true,
	imports: [HeadlineComponent, TranslateModule, IconModule, NgIf, NgClass],
	templateUrl: './offcanvas.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: offcanvasAnimations,
})
export class OffcanvasComponent {
	@Input() open: boolean = false;
	@Input() closeOnOutsideClick: boolean = false;
	@Input() header: string = '';
	@Input() styleClass: string = '';

	/**
	 * if true, the close icon will be shown
	 */
	@Input() showCloseIcon: boolean = true;

	/**
	 * Event emitted when the offcanvas is closed
	 */
	@Output() closed: EventEmitter<void> = new EventEmitter<void>();
	@Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	/**
	 * toggles the open state of the offcanvas and emits the openChange event
	 */
	close() {
		this.open = false;
		this.closed.emit();
		this.openChange.emit(false);
	}
}
